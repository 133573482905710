.app {
  text-align: center;
}

.blinking {
  color: green; /* Set text color to green */
  animation: blink 2s infinite; /* Blinking animation */
}

@keyframes blink {
  0% {
    opacity: 1; /* Fully visible for the first second */
  }
  50% {
    opacity: 1; /* Fully visible for the second half of the cycle */
  }
  100% {
    opacity: 0; /* Invisible for the second second */
  }
}

.departures-container {
  position: relative;
  width: calc(100vw - 20px); /* Fill the horizontal space, subtracting 40px for left and right margin */
  height: calc(100vh - 20px); /* Fill the vertical space, subtracting 40px for top and bottom margin */
  margin: 10px; /* Adjust margin as needed */
}

.departures {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border: none; /* Thick border */
  margin: 0; /* Remove margin */
  padding: 0; /* Remove padding */
}

.departures td {
  border: 2px solid darkgray; /* Thick border */
  padding: 3px; /* Reduce padding */
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis; /* Display ellipsis for truncated text */
  font-size: calc(3vw + 10px); /* Set font size based on screen width */
}

.departures td:first-child {
  max-width: 2vw !important;
}

.departures td:nth-last-child(2) {
  font-size: calc(3vw + 10px);
  padding-left: 2%; /* Add padding to the left */
  max-width: 10vw !important;
}

.departures td:last-child {
  max-width: 6vw !important; /* 2/5ths of the screen width, subtracting border width */
  position: relative;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
  padding-right: 1% !important;
  vertical-align: middle;
}

/* Responsive styles */
@media screen and (max-width: 600px) {
  .departures td {
    max-width: 200px; /* Limit width of cells */
  }
}

.departureNested {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  font-size: calc(1vw + 3px);
  text-align: center;
  height: 100%;
}

.departureCancelled,
.departureDelayed,
.departureSev {
  margin: 0;
  padding: 2px 0;
  font-weight: bold;
  color: white;
  min-width: 100%;
}

.departureCancelled {
  background-color: red;
}

.departureDelayed {
  background-color: #ff8503;
}

.departureSev {
  background-color: purple;
}

.loading-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent background */
  z-index: 9998; /* Ensure it's on top */
}

.loading-text {
  margin: 0;
  font-size: 3vw;
  color: black; /* Text color */
  margin-left: 10px; /* Adjust spacing between text and spinner */
}

.loading-spinner {
  border: 4px solid darkgray; /* Light grey border */
  border-top: 4px solid darkblue; /* Blue border */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite; /* Animation */
  margin-right: 10px; /* Adjust spacing between spinner and text */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255); /* Semi-transparent background */
  z-index: 9997; /* Ensure it's on top */
}

.error-text {
  margin: 0;
  font-size: 3vw;
  color: black; /* Text color */
  margin-left: 10px; /* Adjust spacing between text and spinner */
}

.error-text small {
  font-size: 2vw;
  text-align: center;
}

.red {
  color: red;
}

.settings-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.settings {
  background-color: white;
  padding: 1%;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: 90vw; /* Set width relative to viewport width */
  max-width: 400px; /* Set maximum width for larger screens */
}

.settings h2 {
  margin: 1%;
  font-size: 1.5vw;
}

.settings h2 small {
  font-weight: normal;
}

.settings-content {
  display: flex;
  flex-direction: column;
}

.settings-input {
  font-size: 1vw;
  padding: 1.5%;
  text-align: left;
}

.settings-buttons {
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
  flex-wrap: wrap;
}

.settings-buttons button {
  margin: 1.5%;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 4px;
  cursor: pointer;
  color: black;
  flex-grow: 1;
  background-color: white;
}

.settings-buttons button:last-child {
  border-color: #ff8503;
}

.settings-buttons button:nth-last-child(2) {
  border-color: green;
}

.settings-buttons button:nth-last-child(3) {
  border-color: red;
}

.settings-buttons button:nth-last-child(4) {
  border-color: blueviolet;
}

.settings-buttons button:first-child {
  border-color: gray;
}

.license {
  margin-top: 2%;
  font-size: 0.8vw;
  border-top: 1px solid lightgray;
  padding-top: 2%;
}

.license a {
  text-decoration: none;
}

.license pre {
  margin: 0 !important;
  display: inline !important;
  background-color: rgba(211, 211, 211, 0.5);
  border-radius: 3px;
  padding: 2px;
  cursor: pointer;
}
